import Loadable from 'react-loadable';
import React from 'react';
import { Skeleton } from '../primitives';

export const loadAtlassianSwitcher = () =>
	import(
		/* webpackChunkName: "@ak-switcher-chunk-atlassian-switcher" */ './atlassian-switcher'
	).then((module) => module.default);

export const loadJiraSwitcher = () =>
	import(/* webpackChunkName: "@ak-switcher-chunk-jira-switcher" */ './jira-switcher').then(
		(module) => module.default,
	);

export const loadConfluenceSwitcher = () =>
	import(
		/* webpackChunkName: "@ak-switcher-chunk-confluence-switcher" */ './confluence-switcher'
	).then((module) => module.default);

export const loadGenericSwitcher = () =>
	import(/* webpackChunkName: "@ak-switcher-chunk-generic-switcher" */ './generic-switcher').then(
		(module) => module.default,
	);

export const loadTrelloSwitcher = () =>
	import(/* webpackChunkName: "@ak-switcher-chunk-trello-switcher" */ './trello-switcher').then(
		(module) => module.default,
	);

export const AtlassianSwitcherLoader = Loadable({
	loader: loadAtlassianSwitcher,
	loading: () => <Skeleton />,
});

export const JiraSwitcherLoader = Loadable({
	loader: loadJiraSwitcher,
	loading: () => <Skeleton />,
});

export const ConfluenceSwitcherLoader = Loadable({
	loader: loadConfluenceSwitcher,
	loading: () => <Skeleton />,
});

export const GenericSwitcherLoader = Loadable({
	loader: loadGenericSwitcher,
	loading: () => <Skeleton />,
});

export const TrelloSwitcherLoader = Loadable({
	loader: loadTrelloSwitcher,
	loading: () => <Skeleton />,
});
